{
  "name": "my-esalon",
  "version": "4.8.4",
  "scripts": {
    "ng": "ng",
    "start": "run-script-os",
    "prestart": "node aspnetcore-https",
    "start:windows": "ng serve --ssl --ssl-cert \"%APPDATA%\\ASP.NET\\https\\%npm_package_name%.pem\" --ssl-key \"%APPDATA%\\ASP.NET\\https\\%npm_package_name%.key\" --host=127.0.0.1",
    "start:default": "ng serve --ssl --ssl-cert \"$HOME/.aspnet/https/${npm_package_name}.pem\" --ssl-key \"$HOME/.aspnet/https/${npm_package_name}.key\" --host=127.0.0.1",
    "build": "ng build"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.5",
    "@angular/common": "^18.2.5",
    "@angular/compiler": "^18.2.5",
    "@angular/core": "^18.2.5",
    "@angular/forms": "^18.2.5",
    "@angular/localize": "^18.2.5",
    "@angular/platform-browser": "^18.2.5",
    "@angular/platform-browser-dynamic": "^18.2.5",
    "@angular/router": "^18.2.5",
    "@angular/service-worker": "^18.2.5",
    "@fullcalendar/angular": "^6.1.15",
    "@fullcalendar/core": "^6.1.15",
    "@fullcalendar/daygrid": "^6.1.15",
    "@fullcalendar/interaction": "^6.1.15",
    "@fullcalendar/resource": "^6.1.15",
    "@fullcalendar/resource-daygrid": "^6.1.15",
    "@fullcalendar/resource-timegrid": "^6.1.15",
    "@fullcalendar/resource-timeline": "^6.1.15",
    "@fullcalendar/scrollgrid": "^6.1.15",
    "@microsoft/signalr": "^6.0.0",
    "@ng-bootstrap/ng-bootstrap": "^17.0.1",
    "@ng-select/ng-select": "^13.8.1",
    "@ngneat/input-mask": "^6.1.0",
    "@popperjs/core": "^2.11.8",
    "@tinymce/tinymce-angular": "^7.0.0",
    "bootstrap": "^5.3.3",
    "chrono-node": "^2.7.7",
    "inputmask": "^5.0.8",
    "jwt-decode": "^3.1.2",
    "libphonenumber-js": "^1.10.9",
    "luxon": "^3.0.1",
    "ngx-colors": "^3.5.2",
    "ngx-logger": "^5.0.0",
    "raygun4js": "^3.1.1",
    "rxjs": "~6.6.0",
    "safe-pipe": "^3.0.4",
    "simplebar": "^6.2.5",
    "simplebar-angular": "^3.2.4",
    "sweetalert2": "^11.10.2",
    "tinymce": "^6.8.2",
    "tslib": "^2.0.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/core": "^18.2.5",
    "@angular-devkit/schematics": "^18.2.5",
    "@angular-eslint/builder": "^18.3.1",
    "@angular-eslint/eslint-plugin": "^18.3.1",
    "@angular-eslint/eslint-plugin-template": "^18.3.1",
    "@angular-eslint/schematics": "^18.3.1",
    "@angular-eslint/template-parser": "^18.3.1",
    "@angular/build": "^18.2.5",
    "@angular/cli": "^18.2.5",
    "@angular/compiler-cli": "^18.2.5",
    "@types/luxon": "^1.26.5",
    "@types/raygun4js": "^3.0.0",
    "@typescript-eslint/eslint-plugin": "7.11.0",
    "@typescript-eslint/parser": "7.11.0",
    "@typescript-eslint/utils": "^8.7.0",
    "eslint": "^8.57.0",
    "run-script-os": "^1.1.6",
    "typescript": "~5.5.4"
  }
}
