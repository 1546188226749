import { Component, HostListener, OnInit } from '@angular/core';
import { NotificationsService } from './notifications/services/notifications.service';
import { NGXLogger } from 'ngx-logger';
import { LayoutService } from './app-layout.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

  private _inactivityTimer: any;

  private readonly _inactivityPeriod: number = 300000; // 5 minutes

  public readonly pageLoading$ = this._layout.pageLoading$;

  constructor(
    private readonly _layout: LayoutService,
    private readonly _logger: NGXLogger,
    private readonly _notificationsService: NotificationsService
  ) {}

  private _installUpdates() {
    if (this._notificationsService.updateReady) {
      this._logger.debug('[AppComponent].[InstallUpdates] install updates');
      window.location.reload();
    }
  }

  private _resetInactivityTimer() {
    clearTimeout(this._inactivityTimer);
    this._startInactivityTimer();
  }

  private _startInactivityTimer() {
    this._inactivityTimer = setTimeout(() => {
      this._logger.debug('[AppComponent].[InactivityTimer] expired');
      this._installUpdates();
    }, this._inactivityPeriod);
  }

  ngOnInit() {
    this._startInactivityTimer();
  }

  @HostListener('document:mousemove')
  @HostListener('document:keypress')
  onUserInteraction() {
    this._resetInactivityTimer();
  }

}
