import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { environment } from '../environments/environment';

import { AuthInterceptor } from './auth/auth.interceptor';

import { AppComponent } from './app.component';
import { SharedModule } from '../shared/shared.module';
import { RaygunErrorHandler } from './app.raygun';
import { RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        LoggerModule.forRoot({ level: (environment.production) ? NgxLoggerLevel.INFO : NgxLoggerLevel.TRACE, disableFileDetails: true }),
        SharedModule,
        RouterModule.forRoot([
            { path: '', canActivate: [AuthGuard], loadChildren: () => import('./main/main.module').then(mod => mod.MainModule) },
            { path: 'auth', loadChildren: () => import('./auth/auth.module').then(mod => mod.AuthModule) },
            { path: 'error', loadChildren: () => import('./errors/errors.module').then(m => m.ErrorModule) },
            { path: '**', redirectTo: 'error/404' }
        ])], providers: [
        { provide: ErrorHandler, useClass: RaygunErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
